























































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { ExamRoom, UpgradeLevel, PageNum } from "@/tool/interface-index";
import {
  deleteExamRoom,
  getExamRoomList,
  upgradeSetting,
  getUpgradeTestServeInfo,
  setExamRoom,
  unregisterExamRoom,
} from "@/api/examRoom";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";
import { handleClipboard, clipboardSuccess } from "@/utils/clipboard"; // use clipboard directly
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";

@Component({
  name: "ExamRoomListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private multipleSelection: ExamRoom[] = [];

  private query: {
    schoolName?: string;
    areaId?: string;
    schoolId?: string;
    keyWords: string;
  } = {
    schoolName: "",
    keyWords: "",
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<ExamRoom> = [];

  private clipboardSuccess = clipboardSuccess;
  private handleClipboard = handleClipboard;

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private copyData(examRoom: ExamRoom): string {
    return `${examRoom.schoolName}\n${examRoom.examRoomName}:${examRoom.registrationCode}\n${examRoom.contactPerson}:${examRoom.contactPersonPhone}`;
  }

  private upgradeLevels: { key: string; label: string; value: string }[] = [
    {
      key: "none",
      label: "忽略",
      value: "none",
    },
    {
      key: "hint",
      label: "新版提示",
      value: "hint",
    },
    {
      key: "confirm",
      label: "升级提示",
      value: "confirm",
    },
    {
      key: "required",
      label: "强制升级",
      value: "required",
    },
  ];

  private upgradeSettingForm: {
    examRoom: ExamRoom | undefined;
    upgradeLevel: UpgradeLevel;
  } = {
    examRoom: undefined,
    upgradeLevel: UpgradeLevel.none,
  };
  private upgradeSettingDialogVisible: boolean = false;
  private handleUpgradeSettingClick(examRoom: ExamRoom) {
    this.upgradeSettingForm.examRoom = examRoom;
    this.upgradeSettingForm.upgradeLevel = examRoom.upgradeLevel;

    this.upgradeSettingDialogVisible = true;
    console.log(
      `handleUpgradeSettingClick: ${this.upgradeSettingDialogVisible}`
    );
  }

  handleSelectionChange(val: ExamRoom[]) {
    this.multipleSelection = val;
  }

  private handlePostUpgradeSettingClick() {
    upgradeSetting({
      examRoomId: this.upgradeSettingForm.examRoom!.examRoomId,
      upgradeLevel: this.upgradeSettingForm.upgradeLevel,
    })
      .then((resp) => {
        this.upgradeSettingDialogVisible = false;
        this.upgradeSettingForm.examRoom!.upgradeLevel =
          this.upgradeSettingForm.upgradeLevel;
      })
      .catch((error) => {
        //
      });
  }

  private handleUpgradeSettingDialogClose(done: any) {
    this.upgradeSettingDialogVisible = false;
    /*
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
          */
  }

  private doClipboardSelectionClick(event: MouseEvent) {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }

    let text: string = "";
    for (let i = 0; i < this.multipleSelection.length; i++) {
      const examRoom = this.multipleSelection[i];
      const tmp = this.copyData(examRoom);
      if (text) {
        text = `${text}\n\n${tmp}`;
      } else {
        text = tmp;
      }
    } //for
    this.handleClipboard(text, event);
  }

  private async doCancelRegSelectionClick() {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }
    await this.$confirm(
      `此操作将取消注册${this.multipleSelection.length}个已选考场, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    );
    const tasks: any[] = [];
    for (let i = 0; i < this.multipleSelection.length; i++) {
      const examRoom = this.multipleSelection[i];
      tasks.push(unregisterExamRoom(examRoom));
    }
    await Promise.all(tasks);
    await this.getExamRoomListData();
  }

  private cancel(examRoom: ExamRoom) {
    this.$confirm("此操作将取消注册该考场, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        unregisterExamRoom(examRoom).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "取消成功!",
            });
            this.getExamRoomListData();
          }
        });
      })
      .catch(() => {});
  }

  private deleteData(examRoomId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamRoom(examRoomId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getExamRoomListData();
          }
        });
      })
      .catch(() => {});
  }
  private loading: boolean = false;
  private async getExamRoomListData() {
    try {
      this.loading = true;
      const res = await getExamRoomList(
        _.merge(
          {
            pageSize: this.pageNum.pageSize,
            curPage: this.pageNum.curPage,
          },
          this.query
        )
      );
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }

  private upDataPage() {
    this.getExamRoomListData();
  }
  private setExamRoom(examRoomId: string, type: string) {
    let quickExamRoomName: string = "";
    let quickSchoolName: string = "";
    if (type == "add") {
      switch (this.tableData.length) {
        case 0: {
          quickExamRoomName = "机房一";
          break;
        }
        case 1: {
          quickExamRoomName = "机房二";
          break;
        }
        case 2: {
          quickExamRoomName = "机房三";
          break;
        }
        case 3: {
          quickExamRoomName = "机房四";
          break;
        }
        case 4: {
          quickExamRoomName = "机房五";
          break;
        }
      }
      quickSchoolName = this.query.schoolName as string;
    }
    //
    this.$router.push({
      path:
        type == "add" ? "newExamRoom" : type == "edit" ? "editExamRoom" : "",
      query: {
        examRoomId: examRoomId,
        type: type,
        quickSchoolName,
        quickExamRoomName,
      },
    });
  }

  private upgradeLevelFormatter(row: ExamRoom, column: any) {
    const upgradeLevel = this.upgradeLevels.find((value) => {
      return value.key == row.upgradeLevel;
    });
    if (upgradeLevel) {
      return upgradeLevel.label;
    }
    return "(未设置)";
  }

  private exportExamRoom(data: Array<ExamRoom>) {
    const tHeader = ["学校", "考场名", "联系人", "电话", "注册码", "软件版本"];
    const filterVal = [
      "schoolName",
      "examRoomName",
      "contactPerson",
      "contactPersonPhone",
      "registrationCode",
      "softwareVersion",
    ];
    const data1 = formatJson(filterVal, data);
    exportJson2Excel(tHeader, data1, `机房清单(PAGE${this.pageNum.curPage})`);
  }

  exportExamRoomClick() {
    this.$confirm(
      "非公开数据，需确保数据安全，导出数据需保密, 是否继续?",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(() => {
      this.exportExamRoom(this.tableData);
    });
  }

  queryExamRoomClick() {
    this.pageNum.curPage = 1;
    this.getExamRoomListData();
  }

  activated() {
    if (this.$route.query.schoolName) {
      this.query.schoolName = this.$route.query.schoolName as string;
    }
    this.getExamRoomListData();
  }

  private upgradeProductVersion: string = "";
  mounted() {
    getUpgradeTestServeInfo().then(({ data }) => {
      this.upgradeProductVersion = data.upgradeProductVersion;
    });
  }
}
